/* These placeholder CSS are in main.css too but aren't working there, so repeated here */
.form-control::-moz-placeholder {
    color: #aaaaaa !important;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #aaaaaa !important;
  }
  .form-control::-webkit-input-placeholder {
    color: #aaaaaa !important; 
  }
  