.spinner-container {
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 500ms ease;
	visibility: hidden;
	background-color: white;
}

.show {
	opacity: 0.75; /* Show the spinner with fade-in effect */
	/* display: block; */
	visibility: visible;
	transition: opacity 500ms ease;
}

.spinner {
	display: block;
	margin: auto;
	margin-top: 10em;
	animation: rotate 2s infinite cubic-bezier(0.65, 0.05, 0.36, 1), gradient 800ms infinite linear;
	height: 33%;
	width: 33%;
	/* Extras - too much? */
	/* filter: drop-shadow(0.5rem 0.8rem 0.3rem rgba(0, 0, 0, 0.5)); */
	/* fill: var(--color-std-dark); */
}

/* Define the rotation animation */
@keyframes rotate {
	from 	{ transform: rotate(0); }
	to 		{ transform: rotate(360deg); }
}

@keyframes gradient {
	0% 		{ opacity: 40%; }
	50% 	{ opacity: 80%; }
	100% 	{ opacity: 40%; }
}