
ol.breadcrumb {
	margin-left:15px;
}
#wb-bc a {
	padding-left:0;
	padding-right:0;
}



.calculationResult .textField {
	text-align:left;
}
.calculationResult .dateField, .calculationResult .buttonField {
	text-align:center;
}

.calculationResult .numberField {
	text-align:right;
}


.calculationResult tfoot {
	text-align:right;
	font-weight:bold;
}

.calculationResult td, .calculationResult th {
	border:1px solid black;
	padding:5px;
}

.calculationResult th {
	text-align:center;
	background-color: #335075;
	color: #ffffff;
}

.calculationResult .buttonField {
	padding-left:1px;
	padding-right:1px;
}

.has-error {
	border-color: rgb(169, 68, 66);
}

.mfp-bg {
	opacity: .80;
}

/* react-date-picker custom styling */

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}


.react-date-picker {
  display: inline-flex;
  position: relative;
  padding-right:0px;
  padding-left:6px;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 0px;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-date-picker__inputGroup__leadingZero {
	padding-top: 1px;
	padding-bottom: 1px;
	padding-left: 1px;
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 0px 0px 0px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-date-picker,.form-control {
	padding-top:0px;
	padding-bottom:0px;
}

.react-date-picker__calendar-button button, .react-date-picker__calendar-button span.ui-datepicker-trigger {
	margin: -1px 0px 0px -1px;
}
div.form-control {
	border: 1px solid #ccc;
  border-radius: 4px;
}
span.react-date-picker-calendar-button span.ui-datepicker-trigger {
	border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
input.react-date-picker__inputGroup__input {
	border:0px;
}
.has-error span.react-date-picker-calendar-button span.ui-datepicker-trigger {
	border-color: rgb(169, 68, 66);
}

section.global-alert section.alert {
	margin-top: 0;
	margin-bottom: 0;
}

.alertTimerBar {
	height:0px ;
	padding:0px;
	margin-top:-4px;
	margin-bottom:0px;
	border-bottom-width:4px;
	display:block;
}

#globalAlertClose {
	float:right;
	position: static;
	color:black;
	width:22px;
	height:22px;
	line-height:22px
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300000;
}

.popup-section {
  width: auto;
  max-width: 50rem;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 90%;
  max-height: 90vh; /* Prevents the modal from taking more than 90% of the viewport height */
  border-radius: 8px;
}

.modal-header, .modal-footer {
  flex: 0 0 auto; /* Fixed height */
  padding: 1rem;
  
}

.standard-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.standard-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.modal-body {
  flex: 1 1 auto; /* Allows the body to grow and shrink */
  overflow-y: auto; /* Enables vertical scrolling when content exceeds the body's height */
  padding: 1rem;
}

.no-padding-left {
  padding-left: 0;
}

.bold {
  font-weight: bold;
}

.close-button {
  border: none;
  float: right;
  background: none;
}

.add-button {
  margin-left: 10px;
}

.form-button {
  margin-top: 1rem;
}

/* Overrides colour changes to button background on hover
  Accessibility rejected contrast as not having 3:1 contrast ratio 
  Easier to just get rid of hover
*/
.btn-primary:hover {
  background-color: #335075;
}

.btn-default:hover {
  background-color: #eaebed;
}