.preamble-section {
    margin: 3rem 0;
}

.results-header {
    margin-bottom: 2rem;
}

.results-section {
    margin-left: 2rem;
}